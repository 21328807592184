<template>
  <v-card>
    <v-card-title>
      <span class="font-weight-regular"
        >Clients list that connected on signal server
      </span>
      <v-divider class="ml-2" vertical></v-divider>
      <v-btn @click="get_clients()" icon class="mr-2">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Clients"
        dense
        hide-details
        clearable
        outlined
        class="mr-2"
      ></v-text-field>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="clientTableLoading"
          :headers="headers"
          :items="clients"
          :search="search"
          :items-per-page="30"
          :footer-props="{
            'items-per-page-options': [10, 30, 50, -1],
          }"
        >
          <template v-slot:[`item.deviceId`]="{ item }">
            <v-chip color="indigo" class="white--text" small label>
              <span>{{
                item.deviceId === 'browser'
                  ? `Browser(PeerID): ${item.peerId}`
                  : `Device/Peer ID: ${item.deviceId}`
              }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { apiServiceApi } from '../../services/api.service'
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  created () {
    this.get_clients()
  },
  ...mapGetters(['userInfo']),
  data () {
    return {
      search: null,
      clientTableLoading: false,
      headers: [
        {
          text: 'User Id',
          value: 'userId',
          align: 'center'
        },
        {
          text: 'Name',
          value: 'name',
          align: 'center'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'center'
        },
        { text: 'Device/Peer ID', value: 'deviceId', align: 'center' },
        { text: 'IP Address', value: 'ip', align: 'center' }
      ],
      clients: []
    }
  },
  methods: {
    async get_clients () {
      this.clientTableLoading = true
      await apiServiceApi
        .get('get_clients')
        .then((response) => {
          this.clientTableLoading = false
          this.clients = response.data.data
        })
        .catch((error) => {
          this.clientTableLoading = false
          console.log('error', error)
        })
    }
  }
})
</script>

  <style scoped>
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
